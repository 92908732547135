import { default as index9NurR6PlpfMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/custom/index.vue?macro=true";
import { default as packagesXJMU6EAEmLMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/custom/packages.vue?macro=true";
import { default as partsSEzhxv4HbSMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/custom/parts.vue?macro=true";
import { default as customAxOLWOocTxMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/custom.vue?macro=true";
import { default as indexVD3ckkEd8JMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/index.vue?macro=true";
import { default as ratesXrvlwxA2BGMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/rates.vue?macro=true";
import { default as subscription9gxneqHUHxMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/subscription.vue?macro=true";
import { default as accountNvFmKnfLRUMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account.vue?macro=true";
import { default as _91id_93jEZpZOZsqYMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexMmtw6DfVZcMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93tqaj3Xmc2gMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93faFG4Bi4cHMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/activite/commandes/[id].vue?macro=true";
import { default as indexXwbCD4wYVhMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculesnFED6ZfkXoMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_93C0yFY7bLAUMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/boutique/[title]-[id].vue?macro=true";
import { default as indexsrt8B3Mu93Meta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/boutique/index.vue?macro=true";
import { default as productsT77IAZMB1nMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/boutique/products.vue?macro=true";
import { default as indexzpSifzp46KMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/index.vue?macro=true";
import { default as configurationfwl2yTo2nzMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as index6AN9eKJyLCMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/maintenance/index.vue?macro=true";
import { default as planRgRYiH03tOMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/maintenance/plan.vue?macro=true";
import { default as worksKT6anntnrAMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenancejsO24F9OzJMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/maintenance.vue?macro=true";
import { default as manualsETrYv5uoSiMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/manuals.vue?macro=true";
import { default as timesaXyyv6bkQOMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/times.vue?macro=true";
import { default as catalogAZEfS47gRcMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog.vue?macro=true";
import { default as commandeuKXNgZdOYGMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/commande.vue?macro=true";
import { default as _91tab_93r1nvEfp2GkMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/devis/[tab].vue?macro=true";
import { default as _91id_93Kxi79GZNjoMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93w5fEEGxF8aMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdf8sWK6ItO8KMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/imprimer-pdf.vue?macro=true";
import { default as indexPqdaUVOT4dMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/index.vue?macro=true";
import { default as loginbJd01sd3QnMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/login.vue?macro=true";
import { default as panier3Ni5ZASZruMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/panier.vue?macro=true";
import { default as index1oUSfhNoVLMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/pneumatique/index.vue?macro=true";
import { default as productsuPPQcyl4BOMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/pneumatique/products.vue?macro=true";
import { default as pneumatiques0qjeQEEGTMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/pneumatique.vue?macro=true";
import { default as maintenancebgKWJ3pGcvMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/print/maintenance.vue?macro=true";
import { default as productsfENvLPbljDMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/products.vue?macro=true";
import { default as signupXW9GstMv7EMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/signup.vue?macro=true";
import { default as tableau_45de_45bordTUXDKcAQvPMeta } from "/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountNvFmKnfLRUMeta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account.vue"),
    children: [
  {
    name: customAxOLWOocTxMeta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/activite/vehicules.vue")
  },
  {
    name: "boutique-title-id",
    path: "/boutique/:title()-:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/boutique/[title]-[id].vue")
  },
  {
    name: "boutique",
    path: "/boutique",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/boutique/index.vue")
  },
  {
    name: "boutique-products",
    path: "/boutique/products",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/boutique/products.vue")
  },
  {
    name: catalogAZEfS47gRcMeta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/index.vue")
  },
  {
    name: maintenancejsO24F9OzJMeta?.name,
    path: "maintenance",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdf8sWK6ItO8KMeta || {},
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginbJd01sd3QnMeta || {},
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/panier.vue")
  },
  {
    name: pneumatiques0qjeQEEGTMeta?.name,
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenancebgKWJ3pGcvMeta || {},
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupXW9GstMv7EMeta || {},
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/eitedelinternational.com/dev-app.eitedelinternational.com/pages/tableau-de-bord.vue")
  }
]